import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import { Link } from "gatsby"
import styles from "./about.module.css"
import Layout from "../components/layout"

export default ({ data }) => {
  return (
    <Layout current="about">
      <Header text="header2" />
      <Link to="/">Home</Link>
      {data.site.siteMetadata.title}
      <br />
      {data.allMarkdownRemark.edges[0].node.frontmatter.title}
      {data.allMarkdownRemark.edges[0].node.frontmatter.date}
      <br />
      <div
        dangerouslySetInnerHTML={{
          __html: data.allMarkdownRemark.edges[0].node.html,
        }}
      />

      <br />

      <div className={styles.title}>About Gatsby</div>
      <p>Such wow. Very React.</p>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
          }
          excerpt
          html
        }
      }
    }
  }
`
